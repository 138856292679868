<template>
    <div>
        <a-table :scroll="{ x: 1100 }" :rowKey="(e) => e.id" :columns="columns" :dataSource="opportunities" :pagination="false" :row-selection="rowSelection" class="white-table hide-scrollbar">
            <div slot="customTitle" class="dF aC">
                <div v-if="sort == 'stage'" style="display:flex; flex-direction:column">
                    <a-icon @click="sortDeal('ascending')" type="caret-up" style="font-size:10px; cursor:pointer" :style="sortStage == 'ascending' ? 'color:var(--orange)':''" />
                    <a-icon @click="sortDeal('descending')" type="caret-down" style="font-size:10px; cursor:pointer" :style="sortStage == 'descending' ? 'color:var(--orange)':''" />
                </div>
                <div class="ml-2">Stage</div>
            </div>
            <div style="cursor:pointer" @click="opportunityDetails(obj)" slot="name" slot-scope="obj" class="dF">
                <div>{{obj.name && obj.name != '' ? obj.name : ''}}</div>
            </div>
            <div style="cursor:pointer" @click="opportunityDetails(obj)" slot="value" slot-scope="obj">
                <div>{{`$${$formatNumber(obj.dealValue)}`}}</div>
            </div>
            <div style="cursor:pointer" @click="opportunityDetails(obj)" slot="contact" slot-scope="obj">
                <div>{{obj.contact && obj.contact.firstName && obj.contact.lastName && obj.contact.firstName != '' && obj.contact.lastName != '' ? `${obj.contact.firstName} ${obj.contact.lastName}`:''}}</div>
            </div>
            <div slot="stage" slot-scope="obj">
                <div>{{getStage(obj)}}</div>
            </div>
			<div slot="closeDate" slot-scope="obj">
                <div>{{obj.closeDate && obj.closeDate != 0 ? convertDate(obj.closeDate) : ''}}</div>
            </div>
            <div slot="createDate" slot-scope="obj">
                <div>{{obj.createdAt && obj.createdAt != 0 ? convertDate(obj.createdAt) : ''}}</div>
            </div>
            <div slot="owners" slot-scope="obj" class="dF">
                <template v-for="(user,userI) in obj.owners">
                    <div v-if="userI < 2" :key="user+userI">
                        <a-avatar v-if="user.avatar && user.avatar !== ''" :style="userI !== 0? 'margin-left: -5px' : ''" :src="user.avatar" shape="circle" :size="28"/>
                        <a-avatar v-else :style="userI !== 0? 'margin-left: -5px' : ''" shape="circle" :size="28">{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                    </div>
                </template>
            </div>
            <div slot="extra" slot-scope="obj" class="dF aC">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.048 12.049" fill="#9ea0a5"> <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" class="cls-1" d="M10.542,0H1.506A1.507,1.507,0,0,0,0,1.506V8.283A1.507,1.507,0,0,0,1.506,9.789H3.765v1.977a.283.283,0,0,0,.449.228l2.939-2.2h3.388a1.507,1.507,0,0,0,1.506-1.506V1.506A1.507,1.507,0,0,0,10.542,0Z"/> </svg>
                <div class="ml-1 mr-4">{{obj.notes.length}}</div>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18.622 19.049" style="margin-top: -4px;" fill="#9ea0a5"><g id="Group_4967" data-name="Group 4967" transform="translate(-476.378 -389)"> <path id="Icon_awesome-check-square" data-name="Icon awesome-check-square" d="M13.617,17.5H1.634A1.634,1.634,0,0,1,0,15.867V3.884A1.634,1.634,0,0,1,1.634,2.25H13.617a1.634,1.634,0,0,1,1.634,1.634V15.867A1.634,1.634,0,0,1,13.617,17.5ZM6.649,14.163,12.913,7.9a.545.545,0,0,0,0-.77l-.77-.77a.545.545,0,0,0-.77,0L6.264,11.467,3.879,9.082a.545.545,0,0,0-.77,0l-.77.77a.545.545,0,0,0,0,.77l3.541,3.541a.545.545,0,0,0,.77,0Z" transform="translate(476.378 390.547)"/> <g id="Components_Badges_Number" data-name="Components/Badges/Number" transform="translate(487.292 389)"> <g id="Components_Badges_Number-2" data-name="Components/Badges/Number" transform="translate(0 0)"> <g id="Inner" transform="translate(0)"> <circle id="Oval" fill="#f7941e" cx="4" cy="4" r="4" transform="translate(-0.292)"/> </g> </g> </g> </g> </svg> -->
            </div>
            <div slot="action" slot-scope="obj" class="dF" style="justify-content: flex-end;">
                <!-- <div class="mr-3 edit-delete">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                </div> -->
                <!-- <div class="mr-4 edit-delete">
                    <svg @click="addAppointment(obj)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                </div>
                <div class="mr-4 edit-delete">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                </div> -->
                <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                    <div slot="content">
                        <div @click="opportunityDetails(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye mr-2"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg><div>Quick View</div></div>
                        <div @click="$router.push(`/opportunities/sales/${obj.id}`)" v-if="obj.stage !== 'won' && obj.stage !== 'lost'" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>Edit Opportunity</div>
                        <div v-if="obj.stage != 'won' && obj.stage != 'lost'" @click="addNote(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square mr-2"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg></svg><div>Add Note</div></div>
                        <div v-if="obj.stage != 'won' && obj.stage != 'lost'" @click="addTask(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square mr-2"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg><div>Add Task</div></div>
                        <div v-if="obj.stage != 'won' && obj.stage != 'lost'" @click="addAppointment(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar mr-2"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg><div>Add Appointment</div></div>
                        <div v-if="obj.stage != 'won' && obj.stage != 'lost' && obj.contact && obj.contact.id" @click="emailContact(obj.contact.id)" class="popoverContent dF aC"><svg style="cursor:pointer" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail mr-2"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg><div>Email</div></div>
                        <div @click="$emit('archive',obj)" v-if="obj.stage === 'won' || obj.stage === 'lost'" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>Archive</div>
                        <div @click="$emit('marklost',obj)" v-if="obj.stage !== 'won' && obj.stage !== 'lost'" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-thumbs-down mr-2"><path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path></svg>Mark as Lost</div>
                        <div @click="$emit('markwon',obj)" v-if="obj.stage !== 'won' && obj.stage !== 'lost'" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-thumbs-up mr-2"><path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path></svg>Mark as Won</div>
                        <a v-if="!obj.transaction && obj.stage !== 'won' && obj.stage !== 'lost' && obj.contact && obj.contact.id && obj.contact.id != ''" class="popoverContent dF aC" style="color:rgba(0, 0, 0, 0.65)" :href="`https://transactions.bildhive.${$tld}/new?c=${obj.contact.id}&o=${obj.id}`" target="_blank"><svg width="14" height="14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exchange-alt" class="mr-2 svg-inline--fa fa-exchange-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z"></path></svg><div></div>Add Transaction</a>
                        <!-- <div class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mr-2 feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg><div>Copy Opportunity</div></div> -->
                        <div @click="$emit('deleteopp',obj)" v-if="obj.stage !== 'won' && obj.stage !== 'lost'" class="popoverContent dF aC"><svg style="color:#FD647C" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg><div>Delete Opportunity</div></div>
                    </div>
                    <div class="more-option-icon mr-4">
                        <a-icon style="line-height: 40px;" type="more" />
                    </div>
                </a-popover>
            </div>
        </a-table>
    </div>
</template>

<script>
import  moment from 'moment'

export default {
    props:{
        sort:{
            type:String,
            default:''
        }
    },
    watch:{
        sort:{
            handler(val) {
                this.sortOpportunity(val)
            }
        }
    },
    computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        probs() {
            let probs = {}
            if (this.stages.length) {
                let probList = []
                this.stages.forEach(stage => {
                    if (stage.id != 'lost' && stage.id != 'transaction' && stage.id != 'won') {
                        let obj = {}
                        obj.id = stage.id
                        if (stage.probability) {
                            obj.prob = stage.probability
                            probList.push(obj)
                        }
                        else {
                            obj.prob = 20
                            probList.push(obj)
                        }
                    }
                })
                probList.sort((a,b) => a.prob - b.prob)
                for(var i=1; i<=probList.length; i++) {
                    probs[probList[i-1].id] = i
                }
                probs['transaction'] = probList.length+1
                probs['won'] = probList.length+2;
                probs['lost'] = 0
            }
            return probs
        },
        opportunities(){
            return this.$store.state.contacts.opportunities
        },
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: this.onSelectChange,
                hideDefaultSelections: false,
                onSelection: this.onSelection,
            };
        },
        stages() {
            if (this.$store.state.contacts.allSettings.app && this.$store.state.contacts.allSettings.app.options && this.$store.state.contacts.allSettings.app.options.stages && this.$store.state.contacts.allSettings.app.options.stages.list) {
                return this.$store.state.contacts.allSettings.app.options.stages.list
            } else {
                return []
            }
        },
		nylasAccount() {
			return this.$store.state.contacts.nylasAccount || {}
		}
    },
    data(){
        return{
            selectedRowKeys: [],
            columns:[
                { title: 'Name', key: 'name', scopedSlots: { customRender: 'name' }, sorter: (a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0 },
                { title: 'Value', key: 'value', scopedSlots: { customRender: 'value' }, sorter: (a, b) => a.dealValue - b.dealValue },
                { title: 'Primary Contact', key: 'contact', scopedSlots: { customRender: 'contact' }, sorter: (a, b) => {
					const aName = a.contact && a.contact.firstName && a.contact.lastName;
					const bName = b.contact && b.contact.firstName && b.contact.lastName;
					return aName < bName ? -1 : aName > bName ? 1 : 0
				}},
                { key: 'stage', scopedSlots: { customRender: 'stage' }, slots: { title: 'customTitle' }, sorter: (a, b) => {
					const aStage = this.getStage(a);
					const bStage = this.getStage(b);
					return aStage < bStage ? -1 : aStage > bStage ? 1 : 0
				}},
                { title: 'Opportunity Created Date', key: 'closeDate', scopedSlots: { customRender: 'createDate' }, sorter: (a, b) => {
					const aCreatedAt = +moment(a.createdAt).format('x');
					const bCreatedAt = +moment(b.createdAt).format('x');
					return aCreatedAt < bCreatedAt ? -1 : aCreatedAt > bCreatedAt ? 1 : 0
				}},
                { title: 'Opportunity Close Date', key: 'createDate', scopedSlots: { customRender: 'closeDate' }, sorter: (a, b) => a.closeDate - b.closeDate },
                { title: 'Sales Owner', key: 'owners', scopedSlots: { customRender: 'owners' }},
                { title: '', key: 'extra', scopedSlots: { customRender: 'extra' }},
                { title: '', key: 'action', scopedSlots: { customRender: 'action' }},
            ],
            sortStage:'ascending',
        }
    },
    methods:{
        sortDeal(type) {
            if (this.sortStage != type) {
                this.sortStage = type
                this.sortOpportunity('stage')
            }
        },
        emailContact(contactId) {
            if (contactId != '') {
				if (this.nylasAccount && this.nylasAccount.email){
					this.$store.commit('ADD_LEAD_ACTION', contactId)
					this.$store.commit('ADD_NEW', 'Email')
				} else {
					this.$notification['error']({
						message: 'No Email Connection',
						description: 'You have not connected your mail account. To connect, go to Settings and select Email & Calendar Settings > Integrations.',
						duration: 5
					});
				}
            }
        },
        addNote(obj) {
            this.$store.commit('ADD_NEW', 'Note')
            this.$store.commit('ADD_OPPORTUNITY_ACTION', obj.id)
        },
        addTask(obj) {
            this.$store.commit('ADD_NEW', 'Task')
            this.$store.commit('ADD_OPPORTUNITY_ACTION', obj.id)
        },
        addAppointment(obj) {
            this.$store.commit('ADD_NEW', 'Appointment')
            this.$store.commit('ADD_OPPORTUNITY_ACTION', obj.id)
        },
        opportunityDetails(obj){
            this.$store.commit('OPEN_OPPORTUNITY_DETAILS',obj)
        },
        getStage(opportunity) {
            let stage = this.stages.find(x => x.id == opportunity.stage)
            if (stage) {
				if (opportunity.transaction && opportunity.transaction.length && !opportunity.archived && opportunity.stage != 'won' && opportunity.stage != 'lost') {
					return 'In Transaction';
				}
				else {
					return stage.name;
				}
			} else return "";
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        convertDate(num){
			return moment(num).utc().format(this.dateFormat)
        },
        sortOpportunity(type) {
            if (type == 'date') {
                this.opportunities.sort((a,b) => {
                    return a.closeDate - b.closeDate
                })
            }
            if (type == 'name') {
                this.opportunities.sort((a,b) => {
                    if (a.name > b.name) return 1
                    if (a.name < b.name) return -1
                    else return 0
                })
            }
            if (type == 'value') {
                this.opportunities.sort((a,b) => {
                    return a.dealValue - b.dealValue
                })
            }
            if (type == 'owner') {
                this.opportunities.sort((a,b) => {
                    if (a.contact?.firstName > b.contact?.firstName) return 1
                    if (a.contact?.firstName < b.contact?.firstName) return -1
                    else return 0
                })
            }
            if (type == 'stage') {
                if (this.sortStage == 'ascending') {
                    this.opportunities.sort((a,b) => this.probs[a.stage] - this.probs[b.stage])
                } else {
                    this.opportunities.sort((a,b) => this.probs[b.stage] - this.probs[a.stage])
                }
            }
        }
    },
    created() {
        this.sortOpportunity(this.sort)
    }
}
</script>

<style scoped>
.more-option-icon{
    width: 35px;
    height: 35px;
    border-radius: 35px;
    text-align: center;
    background-color: transparent;
}
.more-option-icon:hover{
    color: var(--orange) !important;
    cursor: pointer;
    background-color: var(--light-gray);
}
.popoverContent{
    height: 35px;
    width: 150px;
    line-height: 35px;
    padding-left: 10px;
}
.popoverContent:hover{
    background-color: var(--off-white-light);
    cursor: pointer;
    color:black;
}
</style>
